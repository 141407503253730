.video {
    position: relative;
    padding-bottom: 0;

    &--margin {
        margin-bottom: 30px;
    }

    &__container {
        position: relative;
        width: 100%;
        height: clamp(250px, 41vw, 800px);

        @include mq(bp-414) {
            height: clamp(300px, 41vw, 800px);
        }

        @include mq(bp-768) {
            height: clamp(500px, 45vw, 800px);
        }
    }

    &__wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        pointer-events: none;

        &.is-disable {
            .video__play-btn {
                opacity: 0;
                pointer-events: none;
            }

            iframe {
                pointer-events: initial;
            }
        }

        &:hover {
            .video__play-icon {
                transform: scale(1.1);
                background-image: url('../../icons/video-play-hover.svg');

            }

            .video__play-txt {
                transform: translateY(0);
            }
        }
    }

    &__play-btn {
        padding: 0;
        display: flex;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: absolute;
        transition: opacity 0.5s;
        border: none;

        &::after {
            @include pseudo();

            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: rgba(#FFE9ED, 0.2);
        }
    }

    &__play-box {
        @include fontr(14px, 16px, 600);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 6;
        pointer-events: initial;
        color: #fff;
        overflow: hidden;
        padding: 10px 5px 3px;
        cursor: pointer;
    }

    &__play-icon {
        display: inline-flex;
        height: clamp(50px, 4.5vw, 80px);
        width: clamp(50px, 4.5vw, 80px);
        background-image: url('../../icons/video-play.svg');
        background-size: contain;
        margin-bottom: 10px;
        transform: scale(0.95);
        transition: transform 0.3s, background-image 0.2s;
    }

    &__play-bg {
        width: 100%;
        object-fit: cover;
        align-self: stretch;
        max-height: 100%;
    }

    &__play-txt {
        transform: translateY(110%);
        transition: transform 0.3s;
    }

    iframe {
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    .about-section__picture {
        display: flex;
        width: 100%;
        height: 100%;
    }

    .about-section__img {
        width: 100%;
        object-fit: cover;
    }
}