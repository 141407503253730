.category-list {
  position: relative;

  .category-title {
    margin-bottom: 15px;
  }

  &__line {
    position: absolute;

    left: 0;
    top: 0;
    height: 100%;
    width: 8px;

    @include mq(bp-992) {
      width: 16px;
    }

    @include mq(bp-1536) {
      width: 24px;
    }
  }

  &__header {
    @include padding(60, 70, 'top');
    padding-bottom: 30px;
    border-bottom: 1px solid $light-rose;
    @include margin(30, 120, 'bottom');
  }

  &__row {
    display: flex;
    flex-direction: column;

    @include mq(bp-992) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__thumb {
    padding-top: 30px;
    max-width: 300px;
    margin-left: auto;

    @include mq(bp-992) {
      padding-top: 0px;
      max-width: 360px;
    }
  }

  &__content {
    position: relative;
    max-width: 660px;
    font-size: 1.6rem;
    padding-left: 40px;

    @include mq(bp-992) {
      padding-left: 70px;
    }

    @include mq(bp-1536) {
      padding-left: 75px;
    }

    svg {
      position: absolute;
      left: 0;
      top: 5px;
      width: 32px;

      @include mq(bp-992) {
        width: 50px;
      }

      @include mq(bp-1536) {
        width: 60px;
      }
    }
  }
}

.line-header {
  position: relative;

  &::before {
    @include pseudo();
    width: 100%;
    background-color: $light-rose;
    top: 0;
    left: 0;
    z-index: -2;

    @include mq(bp-768, max) {
      height: 98%;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;

    @include mq(bp-768) {
      flex-direction: row;
    }
  }

  &__image {
    @include mq(bp-768) {
      width: 40%;
      position: relative;
      left: -40px;
    }
  }

  &__content {
    @include fontr(18px, 22px);

    @include mq(bp-768) {
      width: 60%;
      padding-left: 70px;
      max-width: 700px;
    }

    .small-text {
      display: block;
      margin-top: 30px;
      font-size: 1.6rem;
    }
  }

  .category-title {
    display: flex;
    position: relative;
    align-items: center;

    svg {
      margin-right: 15px;
      width: 32px;

      @include mq(bp-768) {
        width: 64px;
      }
    }
  }
}

.single-category {
  @include margin(30, 50, 'bottom');

  .medium-title {
    @include margin(30, 100, 'bottom');
  }

  .tease-product {
    @include margin(0, 80, 'bottom');
  }
}

.ingredients {
  text-align: center;

  &__title {
    @include fontr(16px, 22px, 700);
    margin-bottom: 15px;
    color: $blue;
  }

  &__content {
    font-family: $heading-font;
    @include fontr(18px, 32px, 500);
    padding: 10px 0;
    color: $blue;
    line-height: 1.6;
  }
}

.header-simple {
  &__desc {
    font-size: 1.6rem;
    max-width: 664px;
  }
}
