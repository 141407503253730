:root {
  --col-padding-big: 15px;
  --col-padding: 15px;
  --history-col: 15px;
  --history-sm: 15px;
}

@include mq(bp-768) {
  :root {
    --col-padding-big: 120px;
    --col-padding: 30px;
    --history-col: 80px;
    --history-sm: 45px;
  }
}

@include mq(bp-1280) {
  :root {
    --col-padding-big: 120px;
    --col-padding: 60px;
  }
}

.about {
  position: relative;

  &__heart {
    z-index: -1;
    position: absolute;
    top: 25%;

    @include mq(bp-768) {
      top: 50%;
      transform: translateY(-30%);
    }

    width: 90%;
    margin: 0 auto;
    left: 0;
    right: 0;

    img {
      width: 100%;
    }
  }
}

.loop-columns {
  @include padding(30, 60, 'top');

  &__row {
    display: flex;
    align-items: center;

    @include mq(bp-768) {
      padding: 45px 0;
    }

    flex-wrap: wrap;

    &.reverse {
      @include mq(bp-768) {
        justify-content: flex-start;
        flex-direction: row-reverse;
        text-align: right;
      }

      .loop-columns__content {
        margin-left: auto;
        padding-right: var(--col-padding-big);
        padding-left: var(--col-padding);
      }
    }
  }

  &__content {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 1.6rem;
    line-height: 1.7;
    max-width: 680px;
    padding-left: var(--col-padding-big);
    padding-right: var(--col-padding);
  }

  &__image {
    img {
      width: 100%;
    }
  }
}

.our-mission {
  @include padding(50, 120, 'bottom');

  &__title {
    @include fontr(16px, 22px, 500);
    margin-bottom: 15px;
  }

  &__image {
    opacity: 0.7;
    @include margin(50, 120, 'bottom');

    @include mq(bp-768) {
      height: 80vh;
      overflow: hidden;
      position: relative;
      clip: auto;
      -webkit-mask-image: -webkit-linear-gradient(top, #fff, #fff);

      img {
        position: fixed;
        top: 20vh;
        left: 0;
        transform: translateZ(0px);
      }
    }
  }

  &__content {
    font-family: $heading-font;
    @include fontr(20px, 48px, 500);
    padding: 10px 0;
    color: $blue;
    line-height: 1.6;
  }
}

.content {
  font-size: 1.6rem;

  p {
    margin-bottom: 1.5rem;
  }
}

.history {
  position: relative;

  &__row {
    flex-direction: column;
    display: flex;

    @include mq(bp-768) {
      flex-direction: row;
    }
  }

  &__heading {
    font-family: $heading-font;
    color: $blue;
    @include fontr(22px, 32px, 500);
    @include margin(15, 35, 'bottom');
    display: block;
  }

  &__heart {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;

    @include mq(bp-768) {
      width: 50%;
    }

    @include mq(bp-1366) {
      transform: translateX(-28%);
    }

    @include mq(bp-1536) {
      transform: translateX(-20%);
    }

    @include mq(bp-1920) {
      transform: translateX(-2%);
    }

    z-index: 2;

    svg {
      width: 100%;
    }
  }

  &__right {
    padding-left: var(--history-col);
    padding-right: var(--history-sm);
    max-width: 625px;
    padding-top: 30px;

    @include mq(bp-768) {
      transform: translateY(100px);
      padding-bottom: 70px;
    }
  }
}

.quality {
  @include padding(50, 120, 'bottom');

  .row {
    @include mq(bp-768, max) {
      flex-direction: column-reverse;
    }
  }

  &__left {
    max-width: 625px;
    margin-left: auto;
  }

  &__right {
    padding-bottom: 30px;

    @include mq(bp-768) {
      padding-left: var(--history-col);
    }
  }
}
