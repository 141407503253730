.search-nav {
    @include margin(40, 130, top);
    @include margin(30, 120, bottom);
    @include padding(30, 120, bottom);

    border-bottom: 1px solid $light-rose;

    &__title {
        @include fontr(28px, 48px, 600);
        @include margin(25, 30, bottom);

        color: $blue;
        line-height: 1.4;
        max-width: 900px;
    }

    &__form {
        display: flex;
        align-items: center;

        input {
            @include fontr(14px, 16px);

            height: 100%;
            margin-right: 10px;
            width: clamp(300px, 28vw, 540px);
            border: 1px solid $light-rose;
            padding: 13px 24px;
            color: $black;

            @include mq(bp-768, max) {
                margin-right: 0;
                padding: 13px 16px;

            }

            &::placeholder {
                color: $black;
            }

            &:focus {
                outline: none;
                border-color: $rose;
                border-radius: 0;
            }
        }
    }
}

.search-products {
    padding-top: 0;
}