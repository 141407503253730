.section-padding {
  @include padding(40, 120, top);
  @include padding(30, 120, bottom);
}

.section-padding-sm {
  @include padding(30, 70, top);
  @include padding(30, 90, bottom);
}

.section-padding-top {
  @include padding(70, 130, top);
}

.section-padding-bottom {
  @include padding(70, 130, 'bottom');
}

.category-title {
  @include fontr(32px, 48px, 600);
  color: $blue;

}

.small-title {
  text-transform: uppercase;
  font-size: 1.4rem;
  @include tracking(25);
  font-family: $heading-font;
}

.medium-title {
  @include fontr(18px, 24px, 600);
  color: $blue;
  margin-bottom: 15px;
}

.section-title {
  @include fontr(18px, 24px, 700);
  @include tracking(25);
  font-family: $base-font;
  margin-bottom: 15px;
  color: $blue;
}

.download-link {
  display: block;
  text-decoration: underline;
  padding-left: 30px;
  position: relative;

  &::before {
    @include pseudo();
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-image: url('#{$base-path}/icons/file-pdf.svg');
    background-position: center center;
    width: 20px;
    height: 20px;
  }


}


.image-title {
  margin-bottom: 30px;
}

.title-container {
  @include margin(15, 30, bottom);
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;

  &__desc {
    @include fontr(16px, 22px);
    line-height: 1.45;
  }

  @include padding(15, 30, bottom);
}