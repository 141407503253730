.quotation {
    @include margin(70, 90, 'bottom');

    position: relative;
    overflow: hidden;

    &__picture {
        top: 0;
        left: 0;
        display: flex;
        position: absolute;
        height: 100%;
        width: 100%;
    }

    &__img {
        object-fit: cover;
        width: 100%;
    }

    &__wrapper {
        @include padding(70, 170, 'top');
        @include padding(60, 130, 'bottom');
        @include padding(20, 130, 'left');
        @include padding(20, 130, 'right');

        z-index: 2;
        position: relative;

    }

    &__desc {
        @include fontr(16px, 18px, 500);
        @include margin(40, 60, top);
        @include margin(20, 30, bottom);

        color: $blue;
        max-width: 460px;

        @include mq(bp-768, max) {
            max-width: 360px;
        }
    }

}