.posts-wrapper {
  display: flex;
  // justify-content: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;

  .col {
    margin-bottom: 30px;
  }
}

.loop-header {
  padding: 0 15px;
  @include padding(40, 160, 'top');
  @include padding(30, 120, 'bottom');
  text-align: center;
  // max-width: 800px;
  margin: 0 auto;

  &__title {
    @include fontr(45px, 80px);

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-family: $second-font;
    color: $blue;
    line-height: 1;


    span {
      position: relative;
      line-height: 1;
      margin-left: 12px;
      text-shadow: 4px 3px 0px #fff;

      &::after {
        @include pseudo();

        width: 100%;
        height: 10px;
        background-image: url('../../icons/underline.svg');
        background-size: cover;
        bottom: 5px;
        bottom: 0.05em;
        left: 0;
        z-index: -2;

        @include mq(bp-992, max) {
          bottom: -0.05em;
        }
      }

      &::before {
        content: attr(data-text);

        color: #fff;
        position: absolute;
        z-index: -1;
        left: -0.05em;
      }
    }
  }
}

.tease-post {

  font-size: 1.6rem;

  @include mq(bp-414) {
    height: 100%;
    padding: 0 15px;
    @include fontr(14px, 16px);
  }

  &__date {
    font-family: $heading-font;
    font-weight: 300;
  }

  &__thumb {
    display: block;
    width: 100%;

    img {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    border: 1px solid $light-rose;
    flex-direction: column;
    transition: all 0.3s;
    height: 100%;

    &:hover {
      background-color: #FFF9FB;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 15px;

    @include mq(bp-1536) {
      padding: 25px;
    }
  }

  &__title {
    font-weight: 600;
    padding: 15px 0;
  }

}

.post {
  &__date {
    font-family: $heading-font;
    font-size: 1.4rem;
    margin-bottom: 15px;
    display: block;
  }

  &__header {
    .return-button {
      margin-top: 0;
    }

    position: relative;

    &-thumb {
      opacity: 0.7;
      max-height: 80vh;
      overflow: hidden;

      @include mq(bp-1536) {
        max-height: 670px;

      }

      img {
        object-fit: cover;
        width: 100%;
      }
    }

    &-content {

      @include mq(bp-768) {
        max-width: 50%;
      }

      @include mq(bp-992) {
        max-width: 40%;
      }
    }

    .container {
      @include padding(30, 60, 'top');
      @include padding(30, 120, 'bottom');

      @include mq(bp-768) {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 100%;
        bottom: 0px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
    }
  }

  &__title {
    @include fontr(24px, 40px, 700);
    color: $blue;
    line-height: 1.3;
  }

}

.return-button {
  position: relative;
  padding-left: 20px;
  margin-bottom: 15px;
  font-size: 1.6rem;

  @include mq(bp-768, max) {
    position: absolute;
    font-size: 1.5rem;
    top: 22px;
  }



  &:hover {
    &::before {
      transform: translate(-10px, -50%) rotate(-180deg)
    }
  }

  &::before {
    @include pseudo();
    width: 13px;
    transition: all 0.3s;
    height: 10px;
    background-image: url('#{$base-path}/icons/arrow_return.svg');
    top: 50%;
    transform: translateY(-50%) rotate(-180deg);
    left: 0;

  }
}

.pressroom {
  &__img {
    @include mq(bp-768, max) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-bottom: 30px;
    }
  }

  img {
    width: 100%;
    max-width: 350px;
  }

  &__row {
    display: flex;
    align-items: center;
  }

  &__content {
    font-family: $heading-font;
    @include fontr(18px, 32px, 500);
    color: $blue;

    @include mq(bp-768) {
      padding-left: 80px;
    }
  }

  &__download {
    display: block;
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: contain;
    background-image: url('#{$base-path}/icons/download_down.svg');
    padding: 0 30px;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    @include padding(15, 70, 'top');

    li {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $light-rose;
      padding: 15px 0;

      @include mq(bp-768, max) {
        flex-wrap: wrap;
      }

      &:first-child {
        border-top: 1px solid $light-rose;
      }

      .title {
        //   margin-right: auto;
        padding-left: 15px;

        @include mq(bp-768, max) {
          padding: 15px 0;
        }
      }
    }

  }
}

.related-posts {
  .tease-post__title {
    color: $blue;
    padding-top: 0;
  }

  .teast-post__cat {
    display: flex;
    @include tracking(25);
    font-size: 1.4rem;
  }

  .tease-post__desc {
    display: none;
  }

  .medium-title {
    @include margin(30, 90, 'bottom');
  }
}

.product-with-text {
  @include padding(15, 70, 'top');
  position: relative;

  &__images {
    padding-top: 30px;

    @include mq(bp-768, max) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 40px;
    }

    @include mq(bp-1280) {
      padding: 0 60px
    }

    img {
      max-width: 500px;
      width: 100%;

      @include mq(bp-768, max) {
        max-width: 300px;
      }
    }

  }

  &__content {

    @include mq(bp-768) {
      padding-left: 45px;
    }
  }

  &__row {
    align-items: center;
    @include margin(15, 30, 'bottom');

  }
}

.article-icons {
  @include margin(15, 30, 'top');

  &__row {
    justify-content: center;
  }

  .icon {
    width: 40px;
    height: 40px;
    background-position: center center;
    background-size: contain;
    margin-bottom: 15px;
  }

  &__col {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @include fontr(16px, 22px, 500);
    padding: 30px;
  }

  img {
    width: 100%;
    max-width: 40px;

  }
}

.article {

  h1,
  h2,
  h3 {
    color: $blue;
  }

  h2 {
    @include fontr(18px, 32px, 600);
    line-height: 1.4;
    margin-bottom: 25px;
  }

  h3 {
    @include fontr(18px, 24px, 600);
    color: $blue;
    margin-bottom: 15px;
  }
}

.article.content {
  @include margin(30, 60, 'top');

  .medium-title {
    @include fontr(18px, 24px, 600);
    margin-bottom: 15px;
  }

  .ingredients__title {
    @include fontr(16px, 22px, 700);
  }

  .loop-columns {
    position: relative;
  }
}

.article-products {
  .medium-title {
    text-align: center;
    @include margin(30, 60, 'bottom');
  }

  .products-wrapper {
    justify-content: center;
  }
}