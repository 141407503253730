body.single-produkty {
  overflow-x: initial;
}
.heart-bg {
  position: absolute;
  width: 80%;

  svg {
    width: 100%;
  }

  margin: 0 auto;
  left: 0;
  right: 0;
  max-width: 1500px;
  z-index: -1;

  // // background-image: url('#{$base-path}/images/heart_big.svg');
  &__product {
    top: 80px;
  }

  &__product-article {
    top: 50px;
    width: 100%;
  }

  &__loop-categories {
    top: 500px;
  }

  &__category {
    top: 220px;
    // transform: translateY(-50%);
  }

  &__news {
    max-width: 1200px;
    top: 450px;
    width: 70%;

    @include mq(bp-992, max) {
      display: none;
    }
  }
}

.product-content {
  position: relative;

  .loop-columns {
    &__image {
      @include mq(bp-992) {
        flex-basis: 43%;
        max-width: 43%;
      }
    }
  }
}

.product {
  .row {
    @include mq(bp-992) {
      align-items: flex-start;
    }
  }

  &-left-col {
    margin-top: -50px;
    position: sticky;
    top: 50px;
    left: 0;

    @include mq(bp-992, max) {
      position: relative;
      margin-bottom: 50px;
      margin-top: 0;
      top: initial;
      left: initial;
    }

    .product-pagination {
      right: 50%;
      transform: translateX(50%);
      margin-top: 10px;

      @include mq(bp-992) {
        margin-top: -20px;
      }
    }
  }

  &__icons {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.6rem;
    line-height: 1.7;

    @include padding(30, 80, 'top');
    @include padding(15, 30, 'bottom');
  }

  .icon {
    &__col {
      @include mq(bp-768, max) {
        padding: 0 15px;
      }

      @include mq(bp-1280) {
        width: 50%;

        &:first-child {
          padding-right: 15px;
        }
      }

      li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }

      img {
        max-width: 19px;
        height: auto;
      }
    }

    &__name {
      padding-left: 8px;
    }
  }

  .col-lg-6 {
    @include mq(bp-992) {
      padding: 0 15px;
    }
  }

  &__line {
    display: flex;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 300;
    @include tracking(25);

    svg {
      max-width: 18px;
      margin-right: 10px;
      align-self: center;
      width: 18px;
    }
  }

  &__row {
    font-size: 1.6rem;
    line-height: 1.7;
    padding: 15px 0 30px 0;
    margin-bottom: 30px;

    &:nth-last-child(1) {
      margin-bottom: 0;

      @include mq(bp-992, max) {
        padding-bottom: 0;
      }
    }
  }

  &__title {
    @include fontr(32px, 42px, 700);
    color: $blue;
  }
}

.image-zoom {
  max-width: 530px;
  margin: 0 auto;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

figure.zoom {
  background-position: 50% 50%;
  position: relative;
  background-size: 150%;
  background-origin: content-box;
  cursor: zoom-in;
  z-index: 2;

  @include mq(bp-992) {
    padding: 50px;
    width: 100%;
  }

  &:hover img {
    opacity: 0;
  }

  img {
    pointer-events: none;
    display: block;
    width: 100%;
    max-width: 400px;
    height: 100%;
    max-height: 350px;
    object-fit: contain;
    transition: opacity 0.5s;

    @include mq(bp-992, max) {
      max-width: 170px;
    }
  }
}

// Reviews
.reviews {
  @include margin(40, 80, 'bottom');
  @include margin(0, 20, 'top');

  &__top {
    @include margin(40, 110, bottom);

    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      margin-bottom: 0;
    }

    &-link {
      @include fontr(14px, 16px, 500);

      color: $blue;
      text-decoration: underline;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;

    @include mq(bp-992, max) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(bp-768, max) {
      grid-template-columns: 1fr;
      max-width: 500px;
    }
  }

  &__item {
    border: 1px solid $light-rose;
    padding: 20px;
    background-color: $white;

    @include mq(bp-992) {
      padding: 24px;
    }

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px;

      @include mq(bp-1024, max) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &-stars {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 5px;

      @include mq(bp-1280) {
        gap: 8px;
      }

      @for $i from 1 to 6 {
        &--#{$i} {
          .reviews__item-star:nth-child(-n + #{$i}) {
            background-image: url('../../icons/full-star.svg');
          }
        }
      }
    }

    &-star {
      display: flex;
      width: 20px;
      height: 20px;
      background-image: url('../../icons/stroke-star.svg');
      background-size: contain;

      @include mq(bp-1280) {
        width: 24px;
        height: 24px;
      }
    }

    &-date {
      @include fontr(12px, 14px, 500);

      color: $blue;

      @include mq(bp-1024, max) {
        order: -1;
        margin-bottom: 7px;
      }
    }

    &-desc {
      @include fontr(13px, 14px, 500);

      color: $blue;
    }

    &-bottom {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-person {
      display: flex;
      align-items: center;

      &-img {
        display: flex;
        width: 40px;
        height: 40px;
        margin-right: 8px;
        object-fit: cover;
        border-radius: 50%;

        @include mq(bp-992, max) {
          width: 30px;
          height: 30px;
        }
      }

      &-name {
        @include fontr(12px, 14px, 500);

        color: $blue;
      }
    }
  }
}
