.container,
.container-m,
.container-l {
    margin: 0 auto;
}

.container {
    width: 90%;

    @include mq(bp-992) {
        max-width: 848px;
    }

    @media (min-width: 1028px) {
        max-width: 992px;
    }

    @include mq(bp-1280) {
        max-width: 1140px;
    }

    @include mq(bp-1366) {
        max-width: 1180px;
    }

    @include mq(bp-1536) {
        max-width: 1360px;
    }

}

.container-l {
    width: 95%;
    max-width: 1770px;
}

.container-m {
    width: 90%;
    max-width: 1600px;
}


.container-md {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;

    @media (min-width: 576px) {
        max-width: 500px;
    }

    @media (min-width: 768px) {
        max-width: 700px;
    }

    @media (min-width: 992px) {
        max-width: 800px;
    }

    @media (min-width: 1200px) {
        max-width: 850px;
    }

    @media (min-width: 1336px) {
        max-width: 950px;
    }

    @media (min-width: 1440px) {
        max-width: 1000px;
    }
}


.padding-v {
    @include padding(80px, 120px, top);
    @include padding(80px, 120px, bottom);
}