.tease-product {
  text-align: center;
  padding: 0 10px;

  &__tags {
    position: absolute;
    left: 50%;
    transform: translate(calc(-50% + 7.5px), -100%);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 7.5px;
    margin-right: -7.5px;
    margin-left: -7.5px;
    margin-top: -7.5px;
    width: 100%;
  }

  &__tag {
    @include fontr(12px, 12px, 400);
    @include tracking(25);

    border: 1px solid $light-rose;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;

    padding: 3px 15px;
    color: #11111c;
    text-transform: uppercase;
    margin: 7.5px;
  }

  picture {
    display: flex;
    align-items: normal;
    justify-content: center;
    height: 250px;

    img {
      object-fit: contain;
    }
  }

  &:hover {
    .btn {
      visibility: visible;
      opacity: 1;
      transform: translateY(0px);
    }

    .tease-product__image-container {
      transform: scale(1.05);
      transform-origin: center;
    }

    .tease-product__bg {
      opacity: 1;
      transform: translateY(-50%) scale(1);
    }
  }

  &__image-container {
    position: relative;
    z-index: 2;
    transition: all 0.3s ease-in-out;
    transform: scale(0.9);
    transform-origin: center 70%;
  }

  &__bg {
    position: absolute;
    z-index: -1;
    transition: all 0.2s;
    opacity: 0;
    transform: translateY(-50%) scale(0.6);
    transform-origin: center 100%;
    top: 50%;
    width: 85%;
    left: 0;
    right: 0;
    margin: 0 auto;

    @include mq(bp-992, max) {
      width: 50%;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin-top: 10px;
  }

  &__line {
    text-transform: uppercase;
    font-weight: 300;
    font-family: $heading-font;
    font-size: 1.4rem;
  }

  &__title {
    color: $blue;
    font-size: 1.8rem;
    font-weight: 700;
    padding: 15px 0;
  }

  &__cat {
    font-size: 1.4rem;
  }

  .btn {
    opacity: 0;
    color: $blue;
    text-decoration: underline;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, transform 0.3s ease-in-out,
      visibility 0.2s;
    transform: translateY(20px);
  }
}
