.button-container {
  padding: 30px 0;
  display: flex;
  justify-content: center;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  font-family: $heading-font;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;

  line-height: 1.2;
  @include padding(15, 20, top);
  @include padding(15, 20, bottom);
  @include padding(30, 50, left);
  @include padding(30, 50, right);

  &__plus {
    background-image: url('#{$base-path}/icons/plus_white.svg');
    background-position: center center;
    width: 0px;
    height: 20px;

    display: block;
    transition: all 0.3s ease;
    position: relative;
    transform: scale(0);
    transition-delay: 0.1s;
    right: -22px;
  }

  &__gradient {
    background-image: $gradient;
    color: #fff;
    height: 48px;
    padding-top: 0;
    padding-bottom: 0;
  }

  &__more {
    position: relative;

    span {
      z-index: 2;
      position: relative;
    }

    &::before {
      @include pseudo();
      width: 100%;
      background-color: $blue;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      opacity: 0;
      // transform: scale(0, 1);
      transition: all 0.5s ease;
    }

    &:hover {
      &::before {
        opacity: 1;
      }

      .btn__plus {
        width: 20px;
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &__white {
    background-color: #fff;
    color: $blue;
    z-index: 1;

    &.btn__more {
      &::before {
        background-color: #fff;
      }
    }

    .btn__plus {
      background-image: url('#{$base-path}/icons/plus.svg');
    }
  }
}
