/******************/
/* ~*~ Config ~*~ */
/******************/
@import './base/core';
@import './base/reset';
@import '~swiper/swiper-bundle.css';
@import '~photoswipe/dist/photoswipe.css';
@import '~photoswipe/dist/default-skin/default-skin.css';
@import '~mapbox-gl/dist/mapbox-gl.css';
@import '~aos/dist/aos.css';
// @import '~photoswipe/dist/photoswipe.css';
// @import '~photoswipe/dist/default-skin/default-skin.css';

/******************/
/* ~*~ Mixins ~*~ */
/******************/
@import './base/rest';
@import './base/responsive';
@import './base/grid';
@import './base/flex';
@import './base/font';
@import './base/retina';

/******************/
/* ~*~ Global ~*~ */
/******************/
@import './global/colors';
@import './global/responsiveness';
@import './global/form';
// @import "./global/keyframes";

/**********************/
/* ~*~ Components ~*~ */
/**********************/
@import './global/global';
@import './components/nav';
@import './components/footer';
@import './components/_slider.scss';

@import './components/texts';
@import './components/buttons';
@import './components/cookies';
@import './components/tabs';
@import './components/select';
@import './components/product';
@import './components/product-list';
@import './components/single-news';
@import './components/video';
@import './components/short-text';
@import './components/quotation';

/******************/
/* ~*~ Layout ~*~ */
/******************/
@import './pages/home';
@import './pages/about';
@import './pages/category';
@import './pages/product';
@import './pages/contact';
@import './pages/search';
// @import "./pages/customer";

#wpadminbar {
  display: none;
}

.gallery {
  @include margin(10, 20, 'top');

  .medium-title,
  &__title {
    @include margin(10, 30, 'top');
    @include margin(30, 70, 'bottom');
  }

  &__grid {
    display: grid;

    grid-gap: 10px;
    grid-template-columns: 1.14fr 1fr;
    grid-template-rows: 198px 132px 198px;

    @include mq(bp-768) {
      grid-gap: 30px;
      grid-template-columns: 1.14fr 1fr 1fr 0.55fr;
      grid-template-rows: 198px 132px;
    }
  }

  &__item {
    display: flex;
    background-color: #444;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    max-height: 362px;

    img {
      object-fit: cover;
      height: auto;
      width: 100%;
    }

    @include mq(bp-768) {
      &-1 {
        grid-column: 1;
        grid-row: 1 / 3;
      }

      &-2 {
        grid-column: 3;
        grid-row: 1 / 3;
      }

      &-3 {
        grid-column: 2;
        grid-row: 1;
      }

      &-4 {
        grid-column: 2;
        grid-row: 2;
      }
    }
  }
}

.wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1.14fr 1fr 1fr 0.55fr;
  background-color: #fff;
  color: #444;
}
