*,
*::before,
*::after {
    box-sizing: border-box;
    background-repeat: no-repeat;
}

html,
body {
    overflow-x: hidden;
}

html {
    font-size: 62.5%;
    line-height: 1.5; // Correct the line height in all browsers.
    -webkit-text-size-adjust: 100%; // Prevent adjustments of font size after orientation changes in iOS.
    -webkit-tap-highlight-color: transparent; //  Remove gray overlay on links for iOS.
}

body {
    margin: 0; // Remove the margin in all browsers.
    font-family: $base-font;
    text-rendering: optimizeSpeed;
    color: #11111C;
}

main {
    display: block; // Render the `main` element consistently in IE
}

p,
table,
blockquote,
address,
pre,
iframe,
form,
figure,
dl {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    margin: 0;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

dt {
    font-weight: bold;
}

dd {
    margin-left: 0;
}

iframe {
    border: 0;
}

address {
    font-style: inherit;
}

hr {
    box-sizing: content-box; // Add the correct box sizing in Firefox.
    height: 0; // Add the correct box sizing in Firefox.
    overflow: visible; // Show the overflow in Edge and IE
}

pre {
    font-family: monospace, monospace; // Correct the inheritance and scaling of font size in all browsers.
    font-size: 1em; // Correct the odd `em` font sizing in all browsers.
}

a {
    background-color: transparent; // Remove the gray background on active links in IE 10.
    text-decoration: none;
    color: inherit;
}

a:active,
a:hover {
    outline-width: 0; // Remove the outline when hovering in all browsers
}

abbr[title] {
    border-bottom: none; // Remove the bottom border in Chrome 57-
    text-decoration: underline; // Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
    text-decoration: underline dotted; // Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
}

b,
strong {
    font-weight: bolder; // Add the correct font weight in Chrome, Edge, and Safari.
}

code,
kbd,
samp {
    font-family: monospace, monospace; // Correct the inheritance and scaling of font size in all browsers.
    font-size: 1em; // Correct the odd `em` font sizing in all browsers.
}

small {
    font-size: 80%; // Add the correct font size in all browsers.
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

img {
    border-style: none; // Remove the border on images inside links in IE 10.
    height: auto;
    max-width: 100%;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit; //  Change the font styles in all browsers.
    font-size: 100%; //  Change the font styles in all browsers.
    line-height: 1.15; //  Change the font styles in all browsers.
    margin: 0; // Remove the margin in Firefox and Safari.
}

button,
input {
    overflow: visible; // Show the overflow in IE.
}

button,
select {
    text-transform: none; // Remove the inheritance of text transform in Firefox.
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button; // Correct the inability to style clickable types in iOS and Safari.
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none; // Remove the inner border and padding in Firefox.
    padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; //  outline: 1px dotted ButtonText;
}

fieldset {
    padding: 0.35em 0.75em 0.625em; // Correct the padding in Firefox.
}

legend {
    box-sizing: border-box; //  Correct the text wrapping in Edge and IE.
    color: inherit; //  Correct the color inheritance from `fieldset` elements in IE.
    display: table; //  Correct the text wrapping in Edge and IE.
    max-width: 100%; //  Correct the text wrapping in Edge and IE.
    padding: 0; // Remove the padding so developers are not caught out when they zero out `fieldset` elements in all browsers.
    white-space: normal; //  Correct the text wrapping in Edge and IE.
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
}

progress {
    vertical-align: baseline; // Add the correct vertical alignment in Chrome, Firefox, and Opera.
}

textarea {
    overflow: auto; // Remove the default vertical scrollbar in IE 10+.
}

[type="checkbox"],
[type="radio"] {
    box-sizing: border-box; // Add the correct box sizing in IE 10.
    padding: 0; // Remove the padding in IE 10.
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto; // Correct the cursor style of increment and decrement buttons in Chrome.
}

[type="search"] {
    -webkit-appearance: textfield; // Correct the odd appearance in Chrome and Safari.
    outline-offset: -2px; //  Correct the outline style in Safari.
}

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; // Remove the inner padding in Chrome and Safari on macOS.
}

::-webkit-file-upload-button {
    -webkit-appearance: button; // Correct the inability to style clickable types in iOS and Safari.
    font: inherit; //  Change font properties to `inherit` in Safari.
}

details {
    display: block; // Add the correct display in Edge, IE 10+, and Firefox.
}

summary {
    display: list-item; // Add the correct display in all browsers.
}

template {
    display: none; // Add the correct display in IE 10+.
}

[hidden] {
    display: none; // Add the correct display in IE 10.
}