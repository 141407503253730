.footer {
  background-color: $light-rose;
  @include padding(40, 80, 'bottom');
  @include padding(30, 60, 'top');

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq(bp-992) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  address {
    font-size: 1.6rem;
    line-height: 1.6;
    text-align: center;

    @include mq(bp-992) {
      text-align: left;
    }

    ul {
      padding-top: 30px;
    }
  }

  &__logo {
    flex: 0.7;

    img {
      max-width: 110px;
    }
  }

  &__col {
    padding-top: 30px;

    @include mq(bp-992) {
      flex: 1;
    }
  }

  &__nav {
    display: flex;

    flex-direction: column;
    align-items: center;

    font-size: 1.4rem;
    color: $blue;
    font-weight: 600;

    @include mq(bp-992) {
      flex: 2.4;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding-right: 30px;
      padding-left: 30px;
    }

    &_item {
      margin-bottom: 15px;
    }

    &__social {
      display: flex;
    }

    .item {
      display: flex;
      position: relative;
      padding: 8px 0px;

      @include mq(bp-992) {
        padding: 4px 0;
      }

      &::before,
      &::after {
        position: absolute;
        width: 100%;
        height: 2px;
        background: $blue;
        bottom: 0;
        left: 0;
        pointer-events: none;
      }

      &::before {
        content: '';
        transform-origin: 100% 50%;
        transform: scale3d(0, 1, 1);
        transition: transform 0.3s;
      }

      &:hover::before {
        content: '';
        transform-origin: 0% 50%;
        transform: scale3d(1, 1, 1);
      }
    }
  }

  &__addit-links {
    display: flex;
    display: grid;
    flex: 1;
    padding: 0 30px;
    margin: 0 30px;

    @include mq(bp-992) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__addit-box {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    & > p {
      font-size: 1.6rem;
      margin-bottom: 10px;

      @include mq(bp-992, max) {
        text-align: center;
      }
    }

    &--right {
      @include mq(bp-992) {
        margin-left: 30px;
      }
    }
  }

  &__addit-nav {
    display: flex;
    flex-direction: column;

    .footer__nav_item {
      margin-bottom: 0;
      margin-left: 0;

      .item {
        display: inline-flex;
        padding-bottom: 5px;
        padding-top: 5px;
        font-weight: 600;

        &::before {
          height: 2px;
        }
      }
    }
  }
}

.social {
  display: flex;
  align-items: center;

  @include mq(bp-992) {
    justify-content: flex-end;
  }

  &:last-child {
    margin-right: 0;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    padding: 5px;
    margin-right: 10px;
    margin-top: -10px;
    transition: all 0.3s ease;
    border-radius: 10px;

    svg {
      fill: $blue;
      width: 100%;
      height: 100%;
    }

    &:hover {
      background-color: $rose;

      svg {
        fill: #fff;
      }
    }
  }
}
