// ================
// Hamburger types
// ================
$hamburger-types: (slider);
$hamburger-padding-x: 0px;
$hamburger-padding-y: 0px;
$hamburger-layer-width: 32px;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 5px;
$hamburger-layer-color: $blue;
$hamburger-layer-border-radius: 30px 0;
$hamburger-hover-opacity: 1;
$hamburger-active-layer-color: $rose;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;
$hamburger-hover-use-filter: false;
$hamburger-hover-filter: opacity(50%);
$hamburger-active-hover-filter: $hamburger-hover-filter;
$menu--items--count: 9;
$transition--length: 0.8;

:root {
  --menu-height: 55px;
  --menu-height-sticky: 66px;
  --submenu-height-sticky: 40px;
  --submenu-height: 66px;
  --menu-margin: 0 15px;
  --menu-margin-sm: 0 9px;
  --font-size: 2vh;
  --font-size-sm: 1.6vh;
  --font-size-sticky: 1.6vh;
  --font-size-sm-sticky: 1.6vh;
  --logo-margin: 5px 0;
  --menu-padding: 10px 0 15px 0;
}

@include mq(bp-768) {
  :root {
    --menu-margin: 0 30px;
    --menu-height: 76px;
  }
}

@include mq(bp-992) {
  :root {
    --menu-padding: 10px 0 22px 0;
    --menu-padding-sm: 8px 0;
    --logo-margin: 12px 0;
    --menu-height: 90px;
  }
}

@include mq(bp-1280) {
  :root {
    --menu-height: 100px;
    --font-size: 2rem;
    --font-size-sm: 1.5rem;
    --font-size-sticky: 1.6rem;
    --font-size-sm-sticky: 1.4rem;
    --logo-margin: 15px 0;
  }
}

@include mq(bp-1366) {
  :root {
    --menu-height: 96px;
  }
}

@include mq(bp-1536) {
  :root {
    --menu-height: 106px;
    --font-size: 2.2rem;
    --font-size-sm: 1.5rem;
    --logo-margin: 20px 0;
  }
}

.nav-height {
  display: block;
  height: var(--menu-height);
}

.nav-wrapper {
  display: block;
  height: calc(2 * var(--menu-height));
}

.nav {
  border-bottom: 1px solid $light-rose;
  background-color: #fff;

  position: relative;

  display: flex;
  z-index: 1030;
  position: fixed;
  transition: all 0.3s;
  width: 100%;

  &__container {
    display: flex;
    justify-content: space-between;

    @include mq(bp-992, max) {
      position: relative;
    }
  }

  &__wrapper {
    display: flex;
    width: 100%;

    @include mq(bp-768) {
      width: 50%;
    }

    @include mq(bp-992) {
      justify-content: center;
      align-items: flex-end;
      width: 100%;
    }

    @include mq(bp-992, max) {
      transform: translateX(100%);
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      visibility: hidden;
      opacity: 0;

      transition: all 0.3s;

      &.is-active {
        visibility: visible;
        opacity: 1;
        transform: translateX(0%);
      }

      // transform: translate3d(-180%, 0%, 0);
      // clip-path: circle(0% at 0% 0%);
      background-color: $light-rose;
      color: #fff;
      flex-direction: column;
      height: 100vh;
      justify-content: center;
    }
  }

  &__menu {
    display: flex;
    align-items: flex-end;

    @include mq(bp-992, max) {
      align-items: center;
      flex-direction: column;
    }
  }

  &__item {
    margin: var(--menu-margin);
    font-size: var(--font-size);
    transition: 0.3s;
    font-size: 18px;
    color: $blue;
    font-weight: 700;

    @include mq(bp-992, max) {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &--active {
      .item::before {
        content: '' !important;
        transform-origin: 0% 50% !important;
        transform: scale3d(1, 1, 1) !important;
      }
    }
  }

  &__item .item {
    display: flex;
    align-items: center;
    position: relative;

    padding: var(--menu-padding);

    &::before,
    &::after {
      position: absolute;
      width: 100%;
      height: 4px;
      background: $blue;
      bottom: 0;
      left: 0;
      pointer-events: none;
    }

    &::before {
      content: '';
      transform-origin: 100% 50%;
      transform: scale3d(0, 1, 1);
      transition: transform 0.3s;
    }

    &:hover::before {
      content: '';
      transform-origin: 0% 50%;
      transform: scale3d(1, 1, 1);
    }
  }

  &__logo {
    font-size: 0;
    margin: var(--logo-margin);
    background-position: center;
    background-size: contain;
    height: 44px;
    min-width: 100px;
    display: none;

    @include mq(bp-768) {
      height: 65px;
      min-width: 110px;
    }

    @include mq(bp-992) {
      display: block;
    }

    &-mobile {
      display: block;

      @include mq(bp-992) {
        display: none;
      }
    }

    background-image: url('#{$base-path}/images/logo.svg');
  }

  &__submenu {
    // display: none;
    border-bottom: 1px solid $light-rose;
    padding: 15px 0;
    background-color: #fff;
    display: none;

    &.open {
      visibility: visible;
      opacity: 1;
      display: block;
    }

    svg {
      width: 16px;
      margin-right: 5px;
      align-self: center;
    }

    transition: 0.3s;

    @include mq(bp-992, max) {
      width: 100%;
      text-align: center;
    }

    @include mq(bp-992) {
      display: flex;
      width: 100%;
      @include padding(15, 50, 'top');
      @include padding(15, 50, 'bottom');
      top: var(--menu-height);
      align-items: center;
      position: absolute;
      left: 0;
      align-items: center;
      opacity: 0;
      visibility: hidden;

      .container {
        display: flex;
        padding-left: 100px;
      }
    }

    &_cat {
      @include fontr(16px, 22px, 400);
      @include margin(15, 35, 'bottom');

      @include mq(bp-992, max) {
        display: none;
      }
    }

    &_col {
      display: flex;
      flex-direction: column;

      @include mq(bp-992) {
        &:nth-child(1) {
          padding-right: 60px;
          border-right: 1px solid $light-rose;
        }

        &:nth-child(2) {
          padding-left: 60px;
        }
      }

      @include mq(bp-1280) {
        &:nth-child(1) {
          padding-right: 120px;
        }

        &:nth-child(2) {
          padding-left: 120px;
        }
      }
    }

    &_item {
      font-size: 1.6rem;
      font-weight: 400;

      a {
        padding: 1vh 0;
        position: relative;
        display: flex;
        color: #11111c;

        @include mq(bp-992, max) {
          align-items: center;
          justify-content: center;
        }

        @include mq(bp-992) {
          padding: 10px 0;

          &::before,
          &::after {
            position: absolute;
            width: 100%;
            height: 2px;
            background: $blue;
            bottom: 4px;
            left: 0;
            pointer-events: none;
          }

          &::before {
            content: '';
            transform-origin: 100% 50%;
            transform: scale3d(0, 1, 1);
            transition: transform 0.3s;
          }

          &:hover::before {
            content: '';
            transform-origin: 0% 50%;
            transform: scale3d(1, 1, 1);
          }
        }
      }
    }
  }

  &.sticky {
    background-color: #fff;
    position: fixed;
    height: var(--menu-height-sticky);
    font-size: 80%;

    .nav__item {
      font-size: var(--font-size-sticky);
    }

    .nav__submenu {
      height: var(--submenu-height-sticky);
      top: var(--menu-height-sticky);
      background-color: #fff;
    }

    .nav__submenu_item {
      font-size: var(--font-size-sm-sticky);
    }
  }

  &__search {
    position: relative;
    margin-left: 30px;

    &--mobile {
      @include mq(bp-992, max) {
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        right: 55px;
        z-index: 20;

        .nav__search-form {
          height: 100%;

          &::after {
            top: 0;
            height: 100%;
          }
        }

        .nav__search-button {
          align-self: center;
          margin: 0 20px;
        }
      }

      @include mq(bp-992) {
        display: none;
      }
    }

    &--desktop {
      display: flex;
      align-items: flex-end;
      padding: var(--menu-padding);

      @include mq(bp-992, max) {
        display: none;
      }
    }

    &-container {
      position: absolute;
      height: 100%;
      width: 200px;
      right: 0;
      bottom: -20px;
      pointer-events: none;
      transition: transform 0.3s;

      @include mq(bp-992, max) {
        bottom: inital;
        top: 0;
      }
    }

    &.is-active {
      .nav__search-form {
        width: 280px;

        @include mq(bp-992) {
          width: 298px;
        }
      }

      .nav__search-button {
        opacity: 1;
        pointer-events: initial;
        transition-delay: 0.4s;
      }
    }

    &-btn {
      cursor: pointer;

      background-color: transparent;
      border: none;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.7;
      }

      img {
        min-width: 18px;
        height: 18px;
      }
    }

    &-form {
      position: absolute;
      bottom: 0;
      display: flex;
      right: 0;
      background-color: #fff;
      transition: width 0.5s;
      width: 0;
      pointer-events: initial;

      @include mq(bp-992) {
        height: 100%;
        transform: translateY(-50%);
      }
    }

    &-wrapper {
      z-index: 2;
      overflow: hidden;
    }

    &-box {
      width: 100%;
      background-color: #fff;
      color: $black;
      height: 100%;
      border: 1px solid $light-rose;
      padding: 8px 30px;
      min-width: 260px;
      font-size: 16px;
      font-weight: 400;

      @include mq(bp-992, max) {
        padding: 8px 20px;
        min-width: 230px;
        font-size: 15px;
      }

      &::placeholder {
        color: $black;
      }

      &:focus {
        outline: none;
      }
    }

    &-button {
      margin-left: 10px;
      margin-right: 10px;
      background-image: url('../../icons/magnifier.svg');
      background-size: contain;
      background-position: center;
      pointer-events: none;
      width: 18px;
      height: 18px;
      z-index: 2;
      opacity: 0;
      border: none;
      cursor: pointer;
      background-color: transparent;
      transition: opacity 0.5s;

      @include mq(bp-992) {
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        background-position: center bottom;
      }
    }
  }

  &__langs {
    @include margin(10, 15, 'left');

    color: $blue;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    padding: var(--menu-padding);
    display: flex;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;

    @include mq(bp-992, max) {
      justify-content: center;
    }

    &:hover,
    &:focus {
      &:after {
        transform: rotate(180deg);
      }

      .nav__langs-wrapper {
        pointer-events: initial;
      }

      .nav__langs-list {
        transform: translateY(0);
      }
    }

    @include mq(bp-992, max) {
      &:focus {
        outline: none !important;
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;

      @include mq(bp-992) {
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translateY(100%);
        overflow: hidden;
        pointer-events: none;
      }
    }

    &-list {
      color: #fff;
      cursor: pointer;

      transition: opacty 0.8s, transform 0.8s, max-height 2s;

      @include mq(bp-992, max) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @include mq(bp-992) {
        transform: translateY(-100%);
        background-color: #efa0ac;
        padding: 10px;
      }
    }
  }

  &__lang-current {
    z-index: 2;
    padding: 0 15px;

    @include mq(bp-992, max) {
      display: none;
    }
  }

  &__lang {
    display: flex;
    margin-bottom: 10px;
    padding: 5px;
    cursor: pointer;
    transition: color 0.3s;

    @include mq(bp-992, max) {
      margin-bottom: 0;
      margin-right: 5px;
      margin-left: 5px;
      line-height: 1;
      padding: 15px;
      background-color: #efa0ac;
    }

    &:hover {
      color: $blue;
    }

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
}

.hamburger {
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: flex;
  align-items: center;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: none;

  @include mq(bp-992) {
    display: none;
  }

  &:hover {
    @if $hamburger-hover-use-filter==true {
      filter: $hamburger-hover-filter;
    } @else {
      opacity: $hamburger-hover-opacity;
    }
  }

  &.is-active {
    &:hover {
      @if $hamburger-hover-use-filter==true {
        filter: $hamburger-active-hover-filter;
      } @else {
        opacity: $hamburger-active-hover-opacity;
      }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $hamburger-active-layer-color;
    }
  }

  &-box {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    display: inline-block;
    position: relative;
  }

  &-inner {
    display: block;
    top: 50%;
    margin-top: $hamburger-layer-height / -2;

    &,
    &::before,
    &::after {
      width: $hamburger-layer-width;
      height: $hamburger-layer-height;
      background-color: $hamburger-layer-color;
      border-radius: $hamburger-layer-border-radius;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.5s;
      transition-timing-function: ease;
    }

    &::before,
    &::after {
      content: '';
      display: block;
    }

    &::before {
      top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }

    &::after {
      bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
  }
}

.menu-item-has-children {
  @include mq(bp-992, max) {
    position: relative;
  }

  &.open_submenu {
    .open-submenu {
      transform: rotate(45deg);
    }

    .nav__submenu {
      display: flex;
    }
  }

  @include mq(bp-992, max) {
    // flex-direction: row;
    // justify-content: center;

    // .item,
    // button {
    //   transform: translateX(11px);
    // }
  }

  .open-submenu {
    position: absolute;
    transition: all 0.3s;
    top: 11px;
    right: 22%;

    @include mq(bp-414) {
      right: 30%;
    }

    @include mq(bp-768) {
      right: 22%;
    }

    @include mq(bp-992) {
      display: none;
    }

    cursor: pointer;
    z-index: 2;
    border: none;
    background-image: url('#{$base-path}/icons/plus_menu.svg');
    background-size: 50%;
    background-position: center center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: $blue;
    margin-left: 5px;
    padding: 2px;
  }

  &:hover {
    .nav__submenu {
      visibility: visible;
      opacity: 1;
      transition-delay: 3000;
    }
  }
}

@import '../hamburger/slider';
