.short-text {
    @include margin(30, 60, 'bottom');
    @include margin(5, 30, 'top');
    @include fontr(20px, 32px, 400);

    font-family: $heading-font;
    padding-bottom: 10px;
    color: $blue;
    line-height: 1.5;

    &__container {
        max-width: 820px;
    }
}