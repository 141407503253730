.contact-header {
    @include padding(50, 120, top);
    @include padding(40, 80, bottom);

    position: relative;

    .image-title {
        @include margin(20, 100, bottom);

        @include mq(bp-768, max) {
            justify-content: flex-start;
        }
    }

    &__row {
        display: grid;
        position: relative;
        z-index: 2;
        gap: 40px;

        @include mq(bp-768) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__col {
        @include fontr(14px, 16px);

        color: $blue;
        line-height: 1.5;

        h2,
        h3,
        h4,
        h5,
        h6 {
            @include fontr(18px, 24px, 700);
            @include tracking(25);

            font-family: $base-font;
            margin-bottom: 20px;

            @include mq(bp-768) {
                margin-bottom: 35px;

            }
        }

        p:not(class) {
            margin-bottom: 2em;
        }

        a {
            transition: color 0.3s;

            &:hover {
                color: $rose;
            }
        }
    }
}

// Map
.map {
    position: relative;
    min-height: 500px;
    height: clamp(500px, 40vw, 780px);

    #contact-map {
        width: 100%;
        height: 100%;

        @include mq(bp-768) {
            position: absolute;
            top: 0;
            bottom: 0;
        }
    }

    .mapboxgl-canvas:focus {
        outline: none;
    }

    .mapboxgl-marker {
        background-image: url('../../icons/marker.svg');
        background-size: contain;
        width: 40px;
        height: 40px;
        opacity: 0;
        animation: fadein 2s forwards;
        animation-delay: 1s;

        svg {
            display: none;
        }

    }

    @keyframes fadein {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }


    &__coords {
        display: none;
    }
}

.contact-form {

    &__title {
        @include margin(30, 70, bottom);

        text-align: center;

        @include mq(bp-768, max) {
            text-align: left;
        }
    }

    @keyframes dash {
        0% {
            stroke-dashoffset: 1000;
        }

        100% {
            stroke-dashoffset: 0;
        }
    }

    @keyframes dash-check {
        0% {
            stroke-dashoffset: -100;
        }

        100% {
            stroke-dashoffset: 900;
        }
    }


    &__form {
        position: relative;
        overflow: hidden;
        padding: 15px 5px;

        &.is-send {
            .sent-msg {
                transform: translateY(0);
            }

            .path {
                stroke-dasharray: 1000;
                stroke-dashoffset: 0;

                &.circle {
                    animation: dash 0.9s 0.2s ease-in-out;
                }

                &.line {
                    stroke-dashoffset: 1000;
                    animation: dash 0.9s 0.55s ease-in-out forwards;
                }

                &.check {
                    stroke-dashoffset: -100;
                    animation: dash-check 0.9s 0.55s ease-in-out forwards;
                }
            }
        }
    }


    &__wrapper {
        width: 100%;
    }

    &__row {
        display: grid;
        column-gap: 32px;

        @include mq(bp-768) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__field {
        position: relative;
        margin-bottom: 25px;

        @include mq(bp-768) {
            margin-bottom: 32px;
        }

        &.is-focus {
            .contact-form__label {
                background-color: $blue;
                color: $white;
                transform: translateY(calc(-200% + 0.9rem)) scale(0.6);
            }

            &.contact-form__message {
                .contact-form__label {
                    transform: translateY(calc(-200% + 1.9rem)) scale(0.6);
                }
            }
        }
    }

    &__label {
        @include fontr(14px, 16px, 400);

        position: absolute;
        color: $black;
        z-index: 1;
        top: 50%;
        line-height: 1.1;
        padding: 4px 6px;
        left: 24px;
        transform: translateY(-50%);
        transform-origin: left;
        transition: transform 0.4s, background-color 0.5s, color 0.2s;
        pointer-events: none;
    }

    &__input {
        @include fontr(14px, 16px, 400);

        padding: 16px 24px;
        line-height: 1;
        width: 100% !important;
        border: 1px solid $light-rose;
        color: black;

        &.wpcf7-not-valid {
            outline: 1px solid red;
        }

        &:focus {
            outline: none !important;
            box-shadow: 0px 0px 1px 0.5px $rose;
        }
    }

    &__message {
        .contact-form__label {
            top: 16px;
            transform: translateY(0);
        }

        .contact-form__input {
            line-height: 1.4;
        }
    }

    br {
        display: none;
    }

    textarea {
        resize: none;
    }

    &__submit-container {
        display: flex;
        justify-content: flex-end;

        @include mq(bp-768, max) {
            justify-content: flex-start;
        }
    }

    &__checkbox {
        width: 20px;
        min-width: 20px;
        height: 20px;
        background-color: transparent;
        position: relative;
        cursor: pointer;
        outline: none;
        margin-top: 0.3rem;
        appearance: initial;
        -moz-appearance: initial;
        margin-right: 12px;

        @include mq(bp-768) {
            width: 24px;
            min-width: 24px;
            height: 24px;
            margin-right: 16px;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
        }

        &::before {
            width: 100%;
            height: 100%;
            background-color: $white;
            border: 1px solid $light-rose;
            left: 0;
            top: 0;
            z-index: 1;
        }

        &::after {
            width: 7px;
            height: 7px;
            background-color: $white;
            left: 50%;
            top: 50%;
            z-index: 2;
            transform: translate(-50%, -50%);
            transition: background-color ease-out 0.25s;

            @include mq(bp-768) {
                width: 8px;
                height: 8px;
            }
        }

        &:checked::after {
            background-color: $blue;
        }
    }

    .wpcf7-acceptance {
        &.wpcf7-not-valid {
            .contact-form__checkbox {
                outline: 1px solid red;
            }
        }

        label {
            display: flex;
            cursor: pointer;
        }

        .wpcf7-list-item {
            margin: 0;
            margin-bottom: 32px;
        }

        .wpcf7-list-item-label {
            @include fontr(11px, 12px, 400);

            color: $black;
            line-height: 1.4;
        }
    }

    .wpcf7-not-valid-tip,
    .wpcf7-response-output {
        display: none;
    }

    .sent-msg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        padding: 20px;
        position: absolute;
        background-color: $white;
        width: 100%;
        height: 100%;
        z-index: 10;
        transform: translateY(-101%);
        transition: transform 0.6s;

        svg {
            margin-bottom: 20px;
            width: clamp(70px, 5vw, 95px);
            height: clamp(70px, 5vw, 95px);
        }

        &__title {
            font-weight: 400;
            margin-bottom: 5px;
        }

        &__txt {
            @include margin(30, 50, 'bottom');
            @include fontr(14px, 16px);

            text-align: center;
            max-width: 650px;
            line-height: 1.5;
        }
    }
}