.products-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .tease-product {
    max-width: 275px;
    margin: 0 auto;

    @include mq(bp-768) {
      flex: 0 0 50%;
      max-width: 50%;
      margin: 0;
    }

    @include mq(bp-992) {
      flex: 0 0 25%;
      max-width: 25%;
    }

    &:nth-last-child(1) {
      @include mq(bp-992, max) {
        margin-bottom: -20px;
      }
    }
  }
}
