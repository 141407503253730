.mission {
  color: $blue;

  &__row {
    display: flex;
    justify-content: flex-end;

    @include mq(bp-768, max) {
      justify-content: flex-start;
      flex-direction: column-reverse;
    }
  }

  &__content {
    @include padding(15, 100, 'left');

    padding-top: 25px;
    padding-bottom: 35px;
    z-index: 3;

    @include mq(bp-768) {
      width: 60%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .container {
    position: relative;
  }

  &__header {
    @include fontr(18px, 24px, 400);
    font-family: $heading-font;
    line-height: 1.7;
  }

  &__image {

    position: relative;
    z-index: 1;
    width: 90%;
    margin-left: auto;

    @include mq(bp-768) {
      width: 58%;
    }

    &::before {
      @include pseudo();
      width: 100%;
      height: 100%;

      background-size: contain;
      background-image: url('#{$base-path}/images/heart_big.svg');
      top: 50%;

      @include mq(bp-768) {
        left: -80%;
        top: 0;
      }

      z-index: -1;
    }
  }

  &__lines {
    @include fontr(18px, 24px, 700);
    @include padding(15, 100, 'left');
    @include padding(40, 100, 'top');

    &-item {
      display: flex;
      align-items: center;
      position: relative;

      &::before {
        @include pseudo();

        width: 31px;
        height: 36px;
        left: 0;
        opacity: 0;
        top: 50%;
        background-image: url('../../icons/stroke-leaf.svg');
        background-size: contain;
        background-position: center;
        transform: translate(-10px, -50%);
        transition: transform 0.3s, opacity 0.3s;
      }

      &:hover {
        &::before {
          opacity: 1;
          transform: translate(-10px, -50%) scale(1.2);
        }

        svg {
          transform: scale(1.2);
        }

        span {
          margin-left: 18px;
          text-decoration: underline;
        }
      }
    }

    li {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }

    span {
      margin-left: 14px;
      transition: 0.3s;
    }

    svg {
      position: relative;
      z-index: 2;
      width: 31px;
      transition: transform 0.3s;
    }
  }
}

.testimonials {
  position: relative;
  overflow: hidden;
  @include padding(30, 180, 'top');
  @include padding(30, 180, 'bottom');

  &__picture {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrapper {
    display: flex;

    @include mq(bp-768, max) {
      flex-wrap: wrap;
    }
  }

  .testimonial {
    display: flex;
    flex-direction: column;
    height: 100%;
    // align-items: center;
    justify-content: center;
    @include fontr(15px, 18px);
    font-family: $heading-font;
    background-color: #fff;
    @include padding(30, 60, 'left');
    @include padding(30, 60, 'right');
    @include padding(70, 150, 'top');
    @include padding(30, 60, 'bottom');
    position: relative;


    &::before {
      @include pseudo();
      width: 28px;
      height: 28px;

      background-size: contain;
      background-image: url('#{$base-path}/icons/quote.svg');

      top: 30px;
      left: 30px;

      @include mq(bp-768) {
        top: 60px;
        left: 60px;
      }
    }

    &__content {
      padding-bottom: 30px;
    }

    &__sign {
      font-size: 1.4rem;
    }

    &__person {
      display: flex;
      align-items: center;

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 15px;
      }
    }

    &__col {
      margin-bottom: 30px;

      &:nth-child(1) {
        padding-right: 15px;
        position: relative;

        @include mq(bp-768) {
          top: 40px;
        }
      }

      &:nth-child(2) {
        padding-left: 15px;
        position: relative;

        @include mq(bp-768) {
          top: -40px;
        }
      }
    }


  }


}

.instagram-slider {
  margin-left: -50px;
  margin-right: -50px;

  @include mq(bp-768) {
    margin-left: -120px;
    margin-right: -120px;
  }

}

.instagram {
  .button-container {
    @include margin(15, 50, 'top')
  }

  &__title {
    display: block;
    color: $blue;
    @include fontr(24px, 40px, 500);
    text-transform: uppercase;
    font-family: $heading-font;
    margin-bottom: 15px;
  }

  .swiper-slide-next {
    @include mq(bp-768, max) {
      transform: scale(1.3);
      z-index: 2;
    }
  }

  .swiper-slide {
    transition: all 0.3s;
  }

  img {
    width: 100%;

    // @include mq(bp-992) {
    //   min-width: 300px;
    // }

  }
}