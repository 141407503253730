/******************/
/* ~*~ Slider ~*~ */
/******************/
$global-index: 1;

.container-slider {
  width: 100%;
}

.slider {
  display: flex;
  position: relative;
  min-height: 100vh;

  &:hover {
    .slider__item-img {
      transform: scale(1.08);
    }
  }

  @include mq(bp-768) {
    //min-height: calc(100vh + 220px);
  }

  width: 100%;

  .swiper-slide-duplicate-active {
    transform: scale(1) !important;
    opacity: 1 !important;
  }

  &__item-bottom {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__bottom-img {
    @include margin(30, 80, 'top');

    transform: translate(50px, 50px);
    opacity: 0;
    transition: opacity 0.1s, transform 1s;
    width: clamp(150px, 15.6vw, 300px);
  }

  &__bottom-product {
    opacity: 0;
    transform: translate(40px, 10px);
    transition: opacity 0.8s;
    width: clamp(150px, 15.6vw, 300px);
    transition-delay: 0;

    @include mq(bp-768, max) {
      transform: translate(20px, 10px);
    }

    &--second {
      @include mq(bp-768, max) {
        display: none;
      }
    }
  }

  &__item-products {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .swiper-slide-active {
    .slider__item-title,
    .slider__item-desc,
    .slider__bottom-img,
    .slider__item-btn {
      opacity: 1;
      transform: translate(0, 0);
    }

    .slider__bottom-product {
      opacity: 1;
      transition-delay: 0.3s;

      &--second {
        transition-delay: 0.6s;
      }
    }

    .slider__item-title {
      transition: transform 1s, opacity 0.9s;
    }

    .slider__item-desc {
      transition: transform 1s, opacity 0.9s;
      transition-delay: 0.2s;
    }

    .slider__item-btn {
      transition: transform 1s, opacity 0.9s;
      transition-delay: 0.4s;
    }

    .slider__bottom-img {
      transition-delay: 0.6s;
    }

    .slider__item-img {
      transition: transform 0.3s, opacity 0.5s;
    }
  }

  &__container {
    min-height: 100%;
    width: 100%;
  }

  .container {
    height: 100%;
  }

  &__item {
    height: 100%;
    will-change: width;
    width: 100%;

    @include mq(bp-768, max) {
      display: flex;
      align-items: center;
    }

    &-box {
      @include padding(100, 200, 'top');
      @include padding(60, 72, 'bottom');

      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 2;
      height: 100%;
      justify-content: center;
    }

    &-title {
      transition: opacity 0.1s, transform 1s;
      transform: translate(50px, 50px);
      opacity: 0;
      width: clamp(230px, 16.6vw, 320px);
    }

    &-desc {
      @include fontr(14px, 16px, 300);
      color: #fff;
      line-height: 1.6;
      transform: translate(50px, 50px);
      opacity: 0;
      transition: opacity 0.1s, transform 1s;
      padding-top: 30px;
      max-width: 380px;
    }

    &-btn {
      transform: translate(50px, 50px);
      opacity: 0;
      display: inline-flex;
      transition: opacity 0.1s, transform 1s;
    }

    .btn {
      @include margin(15, 20, 'top');
    }

    &-picture {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;

      will-change: transform;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-img {
      width: 100%;
      transform: scale(1);

      transition: opacity 0.1s, transform 1s;
      object-fit: cover;
      // object-position: left top;
      will-change: transform;
    }
  }

  &__buttons-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $global-index;
    height: 100%;
    width: 100%;
    pointer-events: none;

    @include mq(bp-768, max) {
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
    }
  }

  .frontpage-slider-pagination,
  &-pagination {
    pointer-events: initial;
    position: absolute;
    bottom: 50px;
    left: 50% !important;
    transform: translateX(-50%);
    width: auto !important;

    @include mq(bp-768, max) {
      bottom: 20px;
    }
  }

  .frontpage-slider-pagination {
    @include mq(bp-768, max) {
      left: 0 !important;
      transform: translateX(0) !important;
    }
  }

  &__nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  &-arrow {
    position: absolute;
    width: clamp(30px, 4vw, 70px);
    bottom: -27px;
    cursor: pointer;
    left: 2vw;
    height: 50px;
    pointer-events: initial;
    z-index: $global-index;
    border: none;
    background: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('#{$base-path}/icons/white-arrow-xl.svg');

    @include mq(bp-992, max) {
      display: none;
    }

    @include mq(bp-992) {
      height: 50px;
    }

    @include mq(bp-1280) {
      height: 60px;
    }

    @include mq(bp-1536) {
      left: 2vw;
      height: 70px;
    }

    @include mq(bp-1920) {
      height: 80px;
      left: 5vw;
    }
  }

  &-button-next {
    right: 2vw;
    left: initial;
    background-image: url('#{$base-path}/icons/white-arrow-xl-right.svg');

    @include mq(bp-1536) {
      right: 2vw;
    }

    @include mq(bp-1920) {
      right: 5vw;
    }
  }
}

.swiper-pagination-bullet {
  background-color: transparent;
  border: 2px solid #fff;
  width: 16px;
  height: 16px;
  margin: 0 5px;
  opacity: 1;

  &.swiper-pagination-bullet-active {
    background-color: #ffe9ed;
    border: 2px solid #ffe9ed;
  }
}

.swiper-pagination--blue {
  .swiper-pagination-bullet {
    border: 2px solid $blue;

    &.swiper-pagination-bullet-active {
      background-color: $blue;
      border: 2px solid $blue;
    }
  }
}
