/* Cookie */
.cookie-box {
  position: fixed;
  z-index: 1040;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $base-black;
  color: $base-white;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateY(0px);

  @include mq(bp-768) {
    max-width: 650px;
  }

  .cookie__column-left {
    @include fontr(9px, 12px, 300);

    color: #bbbbbb;
    // font-family: $second-font-family;
    padding: 15px 20px;
    border-right: 1px solid rgba(255, 255, 255, 0.25);
  }

  .cookie__column-right {
    @include fontr(14px, 14px, 600);
    @include tracking(25);

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    // font-family: $futura-bold;
    color: $base-white;
    padding: 15px 20px;
    text-transform: uppercase;
    transition: all 0.3s;

    &:hover {
      opacity: 0.5;
    }
    span {
      @include mq(bp-768, max) {
        display: none;
      }
    }
    .accept-icon {
      min-width: 17px;

      @include mq(bp-768) {
        margin-left: 15px;
      }
    }
  }

  &--hide {
    opacity: 0;
    transform: translateY(100px);
  }
}
